.privacy-policy-section {
  /* width: 90%;
    margin: auto;
} */
}
.privacy-policy-heading {
  background: #393b37;
  text-align: center;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 120px;
  padding-left: 30px;
  padding-right: 30px;
}
.privacy-policy-heading img {
  width: 22%;
}
.privacy-policy-heading h4 {
  font-size: 34px;
  margin: 0;
  padding: 15px 0;
}
.privacy-policy-heading span {
}
.privacy-policy-content {
  padding: 30px;
}
.privacy-policy-content p {
  margin-bottom: 30px;
}
.privacy-policy-content ul li {
  list-style-type: square;
  margin-top: 10px;
  margin-bottom: 10px;
}
.privacy-policy-content h4 {
  text-transform: uppercase;
  font-size: 22px;
}
.table-of-contents {
}
.table-of-contents a {
  font-size: 16px;
  display: block;
  text-decoration: underline;
  color: rgb(255, 164, 1);
  margin-bottom: 10px;
}
.what-information-we-collect h6,
.what-information-we-collect h6,
.privacy-right h6 {
  text-transform: capitalize;
  text-align: left;
  color: #000;
}

@media screen and (max-width: 778px) {
  .privacy-policy-heading img {
    width: 50%;
  }
}
