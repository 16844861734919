.tesimonial-section {
  position: relative;
  background: #393b37;
  height: 90vh;
}
.testimonial-content {
  padding-top: 150px;
}
.testimonial-content h1 {
  color: #fff;
}
.slider-container {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
  width: 50%;
  margin: auto;
  background: rgb(255, 175, 32);
  padding: 100px 10px 60px;
  text-align: center;
  border-radius: 20px;
}
.single_testimonial_slider {
}
.single_testimonial_slider > div > h2 {
  font-size: 50px;
}
.single_testimonial_slider > div:nth-child(1) {
  background-color: rgb(255, 255, 255);
  color: rgb(255, 164, 1);
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single_testimonial_slider h4 {
  color: rgb(57, 59, 55);
  line-height: 40px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  margin-top: 30px;
}
.single_testimonial_slider span {
  color: rgb(255, 255, 255);
  line-height: 40px;
  font-weight: 500;
  font-size: 20px;
}
.single_testimonial_slider p {
  color: rgb(255, 255, 255);
  line-height: 40px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 26px;
  padding-bottom: 50px;
}
.slider-container .slick-dots {
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-container .slick-dots li.slick-active button::before {
  color: #fff !important;
}
.slider-container .slick-dots li button::before {
  font-size: 12px !important;
}

@media screen and (max-width: 778px) {
  .tesimonial-section {
    height: 60vh;
  }
  .testimonial-content {
    padding-top: 20px;
  }
  .slider-container {
    width: 85%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .single_testimonial_slider h4 {
    margin-top: 15px;
  }
  .single_testimonial_slider p {
    line-height: 30px;
    font-size: 20px;
  }
}
