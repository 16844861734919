.footer-section {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
}
.footer-section > div {
}
.footer-section div > h5 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #393b37 !important;
}
.footer-section div > a {
  font-size: 18px;
  line-height: 60px;
  color: #393b37 !important;
}

@media screen and (max-width: 778px) {
  .footer-section {
    width: 90%;
  }
  .footer-section div > h5 {
    font-size: 1.1rem;
  }
  .footer-section div > a {
    font-size: 14px;
    line-height: 30px;
  }
}
