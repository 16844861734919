.app-feature-section h1 {
  color: rgb(57, 59, 55);
  margin-bottom: 100px;
}
.feature_calculator,
.app-features {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  line-height: 25px;
  margin-bottom: 45px;
}
.feature_calculator > div,
.app-features > div {
  width: 100%;
  text-align: center;
  padding: 30px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 30px 60px 0px;
  border-radius: 20px;
}
.app-features > div {
  padding: 50px 30px;
}
.feature_calculator > div:nth-child(1),
.app-features > div:nth-child(1) {
  margin-right: 12px;
}
.feature_calculator > div:nth-child(2),
.app-features > div:nth-child(2) {
  margin-left: 12px;
}
.app-features > div:nth-child(2) {
  margin-right: 15px;
}
.app-features > div:nth-child(3) {
  margin-left: 12px;
}
.feature_calculator > div > svg,
.app-features > div > svg {
  background: linear-gradient(
    325deg,
    rgb(245, 195, 103) 0%,
    rgb(255, 166, 1) 100%
  );
  font-family: "Roboto";
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 30px;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.feature_calculator > div > h4,
.app-features > div > h4 {
  color: rgb(57, 59, 55);
  line-height: 30px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  text-transform: capitalize;
}
.feature_calculator > div > p,
.app-features > div > p {
  color: rgb(57, 59, 55);
  line-height: 25px;
  letter-spacing: -1px;
  font-weight: 400;
  font-size: 20px;
}

/* Responsive */
@media screen and (max-width: 778px) {
  .app-feature-section h1 {
    margin-left: 50px;
  }
  .app-feature-section {
    width: 90%;
    margin-bottom: 100px;
  }
  .feature_calculator,
  .app-features {
    display: block;
  }
  .feature_calculator > div,
  .app-features > div {
    padding: 30px 20px;
  }
  .feature_calculator > div:nth-child(1),
  .app-features > div:nth-child(1) {
    margin-right: 0px;
  }
  .feature_calculator > div:nth-child(2),
  .app-features > div:nth-child(2) {
    margin-left: 0px;
  }
  .app-features > div:nth-child(2) {
    margin-right: 0px;
  }
  .app-features > div:nth-child(3) {
    margin-left: 0px;
  }
  .feature_calculator > div > h4,
  .app-features > div > h4 {
    font-size: 24px;
  }
  .feature_calculator > div > p,
  .app-features > div > p {
    font-size: 16px;
  }
}
