.screenshot-section {
  width: 60%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 80px;
}
.screenshot-section h1 {
  color: rgb(57, 59, 55);
  padding-bottom: 80px;
}
.single_screenshot_slider {
  display: flex !important;
  justify-content: space-between;
}
.single_screenshot_slider > div:nth-child(1) {
  padding-left: 25px;
}

.single_screenshot_slider > div:nth-child(3) {
  padding-right: 25px;
}
.image-resize {
  margin-top: 50px;
}
.image-resize-two {
  margin-top: 100px;
}
.single_screenshot img {
  width: 70%;
  margin: auto;
  padding: 15px;
  box-shadow: rgba(43, 43, 42, 0.55) -7px 0px 34px 0px;
  border-radius: 60px;
  background: #fff;
  margin-top: 45px;
  margin-bottom: 30px;
}

@media screen and (max-width: 420px) {
  .single_screenshot img {
    border-radius: 25px;
    padding: 10px;
  }
  .single_screenshot {
    height: 450px;
    margin-top: 50px;
  }
  .single_screenshot img {
    width: 80%;
    margin: auto;
    padding: 5px;
    box-shadow: rgba(43, 43, 42, 0.55) -4px 0px 24px 0px;
  }
  .screenshot-section {
    width: 70%;
  }
}

@media screen and (max-width: 778px) {
  .single_slider {
    height: 550px;
  }
  .screenshot-section h1 {
    padding-bottom: 30px;
  }
  .single_screenshot_slider > div:nth-child(1) {
    padding-left: 30px;
    padding-right: 30px;
  }
  .single_screenshot_slider > div:nth-child(2) {
    display: none;
  }
  .single_screenshot_slider > div:nth-child(3) {
    display: none;
  }
}
