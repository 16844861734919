.about-section {
  width: 85%;
  margin: auto;
  margin-bottom: 150px;
}
.about-section h1 {
  color: rgb(57, 59, 55);
  margin-bottom: 15px;
}

.mobileVersion{
  display: none;
}

.websiteVersion{
  display: block;
}

.slider-item-space{
  padding-left: 10%;
  padding-right: 10%;
}

.single_slider {
  position: relative;
  background: linear-gradient(
    140deg,
    rgb(245, 195, 103) 0%,
    rgb(255, 166, 1) 100%
  );
  margin-bottom: 130px;
  margin-top: 220px;
  border-radius: 20px;
  height: 500px;
  width: 80%;
}
.single_slider_content {
  width: 50%;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translateY(-50%);
}
.single_slider_content h3 {
  color: rgba(255, 255, 255, 0.5);
  line-height: 40px;
  font-weight: 700;
  font-size: 36px;
}
.single_slider_content h4 {
  color: rgb(255, 255, 255);
  line-height: 40px;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
}
.single_slider_content p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 30px;
  letter-spacing: -1px;
  font-weight: 300;
  font-size: 22px;
  font-weight: 600;
}
.slider-bg {
  position: absolute;
  top: -30%;
  width: 320px;
  height: 680px;
  z-index: 1000;
  border-radius: 60px;
  left: 5%;
}
.slider-bg img {
  width: 320px;
  height: 680px;
  border-radius: 60px;
  background: #fff;
  padding: 15px;
  box-shadow: rgba(43, 43, 42, 0.55) -20px 0px 40px 0px;
}

/* Responsive */

@media screen and (max-width: 778px) {
  .slider-bg {
    display: none;
  }
  .single_slider {
    height: 450px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .single_slider_content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
  }
  .single_slider_content h3 {
    font-size: 28px;
  }
  .single_slider_content h4 {
    font-size: 24px;
    line-height: 34px;
    padding-right: 20px;
  }
  .single_slider_content p {
    font-size: 16px;
    padding-right: 35px;
  }
  .slick-list{
    width: 100%;
  }
  .slick-next {
    display: none !important;
  }
  .slick-prev {
    display: none !important;
  }
  .mobileVersion{
    display: block;
  }
  .websiteVersion{
    display: none;
  }
}



@media screen and (max-width: 420px) {
  .single_slider_content {
    padding-left: 10px;
  }
  .single_slider {
    height: 480px;
  }
  .single_slider_content h3 {
    font-size: 23px;
  }
  .single_slider_content h4 {
    font-size: 20px;
    line-height: 30px;
    padding-right: 20px;
  }
  .single_slider_content p {
    font-size: 16px;
    text-align: justify;
    padding-right: 25px;
  }
}
