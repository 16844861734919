.button_container {
    position: fixed;
    top: 85px;
    right: 5%;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 100;
    transition: opacity .25s ease;
}

.menuButtonBackground{
    background: linear-gradient( 130deg, rgb(245, 195, 103) 0%, rgb(255, 166, 1) 100% );
    border-radius: 5px;
    padding: 15px;
    transform: translateX(15px) translateY(-10px);
}

.menuButtonBackground span{
    width: 35px !important;
    position: center;
    margin: 15px;
}

.buttonAnimation{
    transition: all .35s ease;
}

.button_container span {
    background: white;
    border: none;
    height: 5px;
    width: 35px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.button_container:hover {
    opacity: .7;
}
.button_container.active .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #FFF;
}
.button_container.active .middle {
    opacity: 0;
    background: #FFF;
}
.button_container.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #FFF;
}

.button_container span:nth-of-type(2) {
    top: 11px;
}
.button_container span:nth-of-type(3) {
    top: 22px;
}

.overlay {
    position: fixed;
    background: #ed9c11;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 1;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
}
.overlay.open {
    opacity: .95;
    visibility: visible;
    height: 100%;
}
.overlay.open li {
    animation: fadeInRight .5s ease forwards;
    animation-delay: .35s;
}
.overlay.open li:nth-of-type(2) {
    animation-delay: .4s;
}
.overlay.open li:nth-of-type(3) {
    animation-delay: .45s;
}
.overlay.open li:nth-of-type(4) {
    animation-delay: .50s;
}
.overlay.open li:nth-of-type(5) {
    animation-delay: .55s;
}
.overlay.open li:nth-of-type(6) {
    animation-delay: .60s;
}
.overlay.open li:nth-of-type(7) {
    animation-delay: .65s;
}

.overlay nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    font-weight: 400;
    text-align: start;
    margin-left: 60px;
}
.overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 80%;
    margin-top: 10%;

}
.overlay ul li {
    display: block;
    height: 25%;
    height: calc(100% / 7);
    min-height: 50px;
    position: relative;
    opacity: 0;
}
.overlay ul li button {
    display: block;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    color: rgb(255, 255, 255);
    line-height: 30px;
    font-family: -apple-system, "Work Sans", sans-serif, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 800 !important;
    font-size: 65px;
    transition: 0.3s;
    background: none!important;
    border: none;
    padding: 0!important;
}

.overlay ul li button:hover{
    color: rgb(57, 59, 55);
}

.overlay ul li button:hover:after, .overlay ul li button:focus:after, .overlay ul li button:active:after {
    width: 100%;
}

@media screen and (max-width: 778px) {
    .button_container {
        top: 70px;
    }
    .overlay ul li button {
        font-weight: 800 !important;
        font-size: 45px;
    }
}

@media screen and (max-width: 420px) {
    .button_container {
        top: 60px;
        right: 30px;
    }
    .button_container:hover {
        opacity: 1;
    }
    .overlay ul li button {
        font-weight: 800 !important;
        font-size: 28px;
        text-align: start;
    }

    .overlay nav {
        margin-left: 20px;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}


