body {
  margin: 0;
  font-family: -apple-system, "Work Sans", sans-serif, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h6 {
  text-transform: uppercase;
  margin: 0;
}

.welcome-to-dyvide h6{
  font-size: 22px;
  margin-top : 120px;
  margin-bottom: 30px;
}

h1 {
  text-align: center;
  line-height: 74px;
  font-weight: 900;
  font-size: 74px;
}

.welcome-to-dyvide h1{
  font-size: 90px;
}
h6 {
  color: rgb(255, 164, 1);
  font-weight: 700;
  font-size: 17px;
  line-height: 46px;
  text-align: center;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}

.slick-dots li.slick-active button::before {
  color: rgb(255, 164, 1) !important;
}
.slick-dots li button::before {
  font-size: 16px !important;
}
.slick-prev {
  left: -70px !important;
}

.slick-next {
  right: -70px !important;
}

/* Responsive */
@media screen and (max-width: 778px) {
  h1 {
    font-size: 38px;
    line-height: 45px;
  }
  h6 {
    margin-top: 20px;
    font-size: 16px;
  }
  .slick-prev {
    left: -30px !important;
  }
  .slick-next {
    right: -30px !important;
  }
  .slick-arrow {
    width: 24px !important;
    height: 24px !important;
  }
}
