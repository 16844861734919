.ambassadors-section {
  background: #393b37;
  height: 65vh;
}
.ambassadors-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.ambassadors-section h1 {
  color: #fff;
  margin-bottom: 50px;
}
.ambassadors-section p {
  margin: auto;
  width: 40%;
  text-align: center;
  font-weight: 500;
  color: rgb(255, 255, 255);
  line-height: 30px;
  letter-spacing: -1px;
  font-size: 25px;
}

@media screen and (max-width: 778px) {
  .ambassadors-section p {
    width: 100%;
    font-size: 20px;
  }
  .ambassadors-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
