.download-app-section {
  height: 90vh;
}
.download-app-container {
  max-width: 350px;
  margin: auto;
}
.download-app-container a > div {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 40px;
  max-width: 100%;
  justify-content: space-between;
  margin-top: 15px;
}

.iosButton {
  padding-right: 65px !important;
}

.download-app-container a > div:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}
.download-app-container a > div > svg {
  font-size: 56px;
  color: rgb(255, 164, 1);
}
.download-app-container a > div > div > p {
  margin: 0;
  color: #fff;
  margin-bottom: -10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.download-app-container a > div > div > h4 {
  margin: 0;
  font-size: 30px;
  color: #fff;
}
.footer-p p {
  color: rgb(255, 255, 255);
  line-height: 25px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin-top: 120px;
}

@media screen and (max-width: 778px) {
  .download-app-section {
    height: 80vh;
  }
  .download-app-container {
    max-width: 80%;
    margin-top: 30px;
  }
}
