.get-app-section {
  margin: auto;
  margin-top: 600px;
  margin-bottom: 200px;
  display: flex;
  width: 50%;
  justify-content: center;
}

.android{
  margin-left: 60px;
}
.get-app-section a > div {
  display: flex;
  align-items: center;
  border: 1px solid rgb(57, 59, 55);
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px 25px;
}
.get-app-section a > div:hover {
  border: 1px solid rgb(255, 164, 1);
}
.get-app-section a > div > svg {
  font-size: 56px;
  color: rgb(255, 164, 1);
}
.get-app-section a > div > div > p {
  margin: 0;
  color: #414141;
  margin-bottom: -5px;
}
.get-app-section a > div > div > h4 {
  margin: 0;
  font-size: 30px;
  color: rgb(57, 59, 55);
}

@media screen and (max-width: 1300px) {
  .get-app-section {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 420px) {
  .get-app-section {
    margin-top: 200px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 778px) {
  .get-app-section {
    display: block;
    margin-top: 400px;
    margin-bottom: 50px;
    width: 60%;
  }
  .get-app-section a > div {
    padding: 10px 10px;
    margin-bottom: 25px;
  }


}
