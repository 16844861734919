.team-section {
  margin-top: 300px;
  margin-bottom: 200px;
}
.team-section h1 {
  color: rgb(57, 59, 55);
}
.team-container {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 80px;
}
.team-container > div {
  position: relative;
  width: 100%;
}
.team-container > div:nth-child(1) {
  margin-right: 12px;
}
.team-container > div:nth-child(2) {
  margin-left: 12px;
}

.team-container > div{
  max-width: 600px;
}
.team-container > div > img {
  width: 100%;
  max-width: 600px;
  max-height: 450px;
  border-radius: 20px;
  object-fit: cover;
}
.team-info {
  color: #fff;
  position: fixed;
  text-align: center;
  bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}



.team-info h4 {
  line-height: 50px;
  letter-spacing: -1px;
  font-weight: 700;
  font-size: 30px;
  margin: 0;
}
.team-info p {
  line-height: 20px;
  letter-spacing: -1px;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}
.team-info ul {
  display: flex;
  justify-content: center;
  margin-left: -40px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
.team-info ul li {
  padding-left: 10px;
  padding-right: 10px;
}
.team-info ul li a {
}
.team-info ul > li > a {
  line-height: 10px;
  letter-spacing: -1px;
  font-size: 23px;
  font-weight: 600;
  color: #fff;
}
.memberCard:hover ul {
  display: flex;
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 1s 0ms;
}

@media screen and (max-width: 778px) {
  .team-container {
    display: block;
    width: 90%;
  }
  .team-container > div:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .team-container > div:nth-child(2) {
    margin-left: 0px;
  }
  .team-info h4 {
    font-size: 26px;
  }
  .team-info p {
    font-size: 16px;
  }
  .team-info ul > li > a {
    font-size: 14px;
  }
  .team-info {
    bottom: 25px;
  }
  .team-section {
    margin-bottom: 100px;
  }
  .team-container {
    margin-top: 50px;
  }
}
