.header-section {
  background: #393b37;
  height: 100vh;
}
.navbar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding-top: 50px;
  padding-right: 160px;
}
.logo img {
  width: 50px;
  height: 50px;
  margin-left: 30px;
}
.navbar-section > nav > ul {
  display: flex;
  align-items: center;
}
.navbar-section > nav > ul > li {
  margin-left: 40px;
}
.navbar-section > nav > ul > li > a {
  color: #fff;
}



.availiOSAndroid{
  background: transparent;
  border-color: transparent;
  border-style: solid;
  color: white;
  line-height: 65px;
  font-size: 16px;
  cursor: pointer;
  font-family: -apple-system, "Work Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.downloadButton{
  background: linear-gradient(
          130deg,
          rgb(245, 195, 103) 0%,
          rgb(255, 166, 1) 100%
  );
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  line-height: 65px;
  font-weight: 500;
  font-size: 16px;
  padding-left: 25px;
  border-radius: 5px;
  padding-right: 25px;
  border: none;
  transition: 1s;
  cursor: pointer;
}

.downloadButton:hover{
  background: linear-gradient(
          130deg,
          rgb(255, 166, 1) 0%,
          rgb(255, 166, 1) 100%
  );
}

.header-section h1 {
  color: #fff;
}
.header-screens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  margin: auto;
  margin-top: 170px;
}
.header-screens img {
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  box-shadow: rgba(43, 43, 42, 0.55) -20px 0px 60px 0px;
  border-radius: 50px;
  background: #fff;
  padding: 15px;
}
.header-screens img:nth-child(1) {
  width: 26%;
  height: 20%;
}
.header-screens img:nth-child(2) {
  width: 31%;
  height: 35%;
}
.header-screens img:nth-child(3) {
  width: 26%;
  height: 20%;
}

.instagram-li {
  margin-top: 20px;
}

.instagram-li a {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

/* Responsive */

@media screen and (max-width: 778px) {
  .navbar-section > nav > ul > li:nth-child(1) {
    display: none;
  }
  .navbar-section > nav > ul > li:nth-child(2) {
    display: none;
  }
  .navbar-section > nav > ul > li > a > svg {
    height: 40px;
    width: 40px;
  }
  .welcome-to-dyvide {
    padding-left: 15px;
    padding-right: 15px;
  }


  .welcome-to-dyvide h6{
    margin: 100px 0 100px !important;
  }
  .welcome-to-dyvide h1{
    line-height: 80px;
    font-size: 4em;
    text-align: center;
  }
  .welcome-to-dyvide .subtitle{
    color: #fff;
  }

  .header-screens {
    width: 60%;
    margin: 0;
    margin-top: 150px;
    align-items: center;
    flex-direction: row;
    align-content: center;
  }

  .header-screens img{
    border-radius: 40px;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header-screens img:nth-child(1) {
    width: 45%;
    height: 39%;
  }
  .header-screens img:nth-child(2) {
    width: 55%;
    height: 59%;
  }
  .header-screens img:nth-child(3) {
    width: 45%;
    height: 39%;
  }


  .hamburger-menu-container {
    margin-top: 20px;
  }
  .side-drawer-open {
    width: 100%;
  }
  .hamburger-menu-item ul li a {
    font-size: 28px;
    line-height: 10px;
  }
  .side-drawer-open {
    width: 100%;
    padding: 0px;
  }
  .side-drawer-close {
    position: fixed;
    left: -200%;
  }
  .close-icon {
    margin-right: 20px;
  }
}

@media screen and (max-width: 420px) {
  .header-screens {
    width: 50%;
  }

  .header-screens img{
    border-radius: 25px;
  }

  .navbar-section {
    width: 100% !important;
    padding-top: 50px;
    padding-right: 0px;
  }

}



